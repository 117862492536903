/*
  Copyright 2019 Chevron. All Rights Reserved.
*/
@use '@angular/material' as mat;

@import 'src/styles/material/theme.scss';

// Header
$header-background-color: mat.get-color-from-palette($mat-app-accent, 40);

// Graphs
$graph-width: 732;
$graph-height: 300;

// Primary Colors
$dark-blue: mat.get-color-from-palette($mat-app-primary, 900);
$blue: mat.get-color-from-palette($mat-app-primary, 500);
$vivid-blue: mat.get-color-from-palette($mat-app-primary, 450);
$grey-blue: mat.get-color-from-palette($mat-app-primary, 250);
$light-blue: mat.get-color-from-palette($mat-app-primary, 75);

// Secondary Colors
$red: mat.get-color-from-palette($mat-app-warn, 450);
$yellow: mat.get-color-from-palette($mat-app-warn, A800);
$green: mat.get-color-from-palette($mat-app-primary, 920);

// Greys
$light-grey: mat.get-color-from-palette($mat-app-accent, 300);
$black: mat.get-color-from-palette($mat-app-accent, A200);
$medium-grey: mat.get-color-from-palette($mat-app-accent, 400);
$dark-grey: mat.get-color-from-palette($mat-app-accent, 750);
$off-black: mat.get-color-from-palette($mat-app-accent, 850);
$soft-white: mat.get-color-from-palette($mat-app-accent, 250);
$pure-white: mat.get-color-from-palette($mat-app-accent, 40);
$off-white: mat.get-color-from-palette($mat-app-accent, 150);
