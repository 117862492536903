/*
  Copywrite 2019 Chevron. All Rights Reserved.
*/
@use '@angular/material' as mat;

//@import '@angular/material/theming';
@import 'palette';

@include mat.core();

@include mat.all-component-themes($mat-app-theme);
