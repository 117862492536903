/* You can add global styles to this file, and also import other style files */
// @import "~bootstrap/dist/css/bootstrap.css";

// Icons
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

// Theme
@import 'src/styles/_variables.scss';

// Other


// Fonts


html,
body {
  margin: 0;
  height: 100%;
  width: 100%;
  font-size: 14px;
  font-weight: 300 !important;

  // & * {
  //   box-sizing: border-box;
  // }
}

.mat-dialog-container {
  padding: 0 !important;
 }

