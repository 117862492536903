/*
  Copyright 2019 Chevron. All Rights Reserved.
*/
@use '@angular/material' as mat;

//@import '@angular/material/theming';

$md-mcgpalette1: (
    50 : #e8eaf6,
    75 : #D5E4EF,
    100 : #c5cae9,
    200 : #9fa8da,
    250 : #899abe,
    300 : #7986cb,
    400 : #5c6bc0,
    450 : #009DD9,
    500 : #0066b2,
    600 : #3949ab,
    700 : #303f9f,
    800 : #283593,
    900 : #0b2d71,
    920 : #769231,
    A100 : #8c9eff,
    A200 : #536dfe,
    A400 : #3d5afe,
    A700 : #304ffe,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$md-mcgpalette2: (
    40 : #ffffff,
    50 : #fafafa,
    100 : #f5f5f5,
    150 : #f8f8f8,
    175 : #F0F0F0,
    200 : #eeeeee,
    250 : #ededee,
    300 : #dbdcdd,
    350 : #d3d3d3,
    400 : #8c8f93,
    500 : #9e9e9e,
    600 : #757575,
    700 : #616161,
    750 : #6b6d6f,
    800 : #424242,
    850 : #343a3e,
    900 : #212121,
    A100 : #ffffff,
    A200 : #000000,
    A400 : #303030,
    A700 : #616161,
    A800 : #6b6d6f,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$md-mcgpalette3: (
    50 : #ffebee,
    100 : #ffcdd2,
    200 : #ef9a9a,
    300 : #e57373,
    400 : #ef5350,
    450 : #e21836,
    500 : #f44336,
    600 : #e53935,
    700 : #d32f2f,
    800 : #c62828,
    900 : #b71c1c,
    A100 : #ff8a80,
    A200 : #ff5252,
    A400 : #ff1744,
    A700 : #d50000,
    A800 : #faab18,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$mat-app-primary: mat.define-palette($md-mcgpalette1);
$mat-app-accent: mat.define-palette($md-mcgpalette2);
$mat-app-warn: mat.define-palette($md-mcgpalette3);

$mat-app-theme: mat.define-light-theme($mat-app-primary, $mat-app-accent, $mat-app-warn);
